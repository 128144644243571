import {
    DeleteButton, SimpleForm, Button, Datagrid, List, TextField, BooleanField, Edit, TextInput, DateInput, DateTimeInput, ReferenceInput, ArrayInput, SimpleFormIterator,
    SelectInput, NumberInput, AutocompleteArrayInput, AutocompleteInput, TabbedForm,FormTab, ReferenceManyField, Pagination, Create, Show, Filter, FunctionField
} from "ra-ui-materialui";
import { useEffect, useState, useCallback } from "react";
import { getActivityType, getActivityTypeValue, getClientType, getLanguajes, getLikes, getTherapeuticProfile, getWorkingCenter, getProfessionalType } from "../services/master-tables.service";
import { InteractionDto, ScheduledActivityClient, ExternalInformationClient, ProfessionalClient } from "../services/ohl.virtualCenter.api";
import { iconListFont } from "./icons.js"

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import moment from "moment";
import { parseDate } from "./scheduledActivities";
import { CustomToolbarPermissions, PostBulkActionButtonsPermision } from "./utils";
import { FormDataConsumer, required, useRedirect, useMutation, useGetIdentity } from "ra-core";
import { ChipArrayUseStyles, TabsUseStyles } from "../App";
import { useFormState } from 'react-final-form';
import { WorkingCenterField } from "../components/Fields/WorkingCenterField";
import AutoCompleteCustom from "../components/AutoCompleteCustom"
import ActionDelete from '@material-ui/icons/Delete';
import { useLocation } from 'react-router';
import {
    useRefresh,
    useNotify,
    useUpdate,
    useRecordContext,
    DatagridBody,
    Labeled 
} from "react-admin";
import { withStyles } from '@material-ui/core/styles';
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CalendarIcon from "@material-ui/icons/CalendarTodayRounded";
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import StarPicker from 'react-star-picker';
import ReactQuill from 'react-quill';
import MUIButton from '@material-ui/core/Button';
import MUITabs from '@material-ui/core/Tabs';
import MUITab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import 'react-quill/dist/quill.snow.css';
import '../css/reactQuillMod.css';
import ReactHtmlParser from 'react-html-parser';
import '../css/user.css';
import { saveAs } from 'file-saver';
import { makeStyles } from '@material-ui/core/styles';
// import jsPDF from 'jspdf'
//// eslint-disable-next-line
// import autoTable from 'jspdf-autotable';
import jsonExport from 'jsonexport/dist';
import { downloadCSV } from "react-admin";
import { btoaReplacement } from "../common/utils";

import scheduledActivitiesProvider from "../provider/scheduledActivities.provider";

const interactionsClient = new ExternalInformationClient(process?.env?.REACT_APP_PUBLIC_API);
const professionalClient = new ProfessionalClient(process?.env?.REACT_APP_PUBLIC_API);
const scheduledActivityClient = new ScheduledActivityClient(process?.env?.REACT_APP_PUBLIC_API);
var XLSX = require("xlsx");

library.add(fas)

const validateActivityCreate = (values) => {
    const errors = {};
    if (!values?.name?.trim()) {
        errors.name = 'Requerido';
    }
    if (!values?.description?.trim()) {
        errors.description = 'Requerido';
    }
    if (!values?.initDate?.trim()) {
        errors.initDate = 'Requerido';
    }
    if (moment(values?.initDate).isBefore((new Date()).toDateString())) {
        errors.initDate = 'La fecha de inicio no puede ser anterior a la actual';
    }
    if (!/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(values?.initHour)) {
        errors.initHour = 'Formato incorrecto';
    }
    if (!/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(values?.endHour)) {
        errors.endHour = 'Formato incorrecto';
    }
    if (!values?.initHour?.trim()) {
        errors.initHour = 'Requerido';
    }
    if (!values?.endHour?.trim()) {
        errors.endHour = 'Requerido';
    }
    // Check initHour < endHour
    if (values.initHour && values.endHour) {
        const initDate = moment();
        const endDate = moment();
        initDate?.set({ hour: values?.initHour?.split(':')[0], minute: values?.initHour?.split(':')[1] })
        endDate?.set({ hour: values?.endHour.split(':')[0], minute: values?.endHour?.split(':')[1] })
        if (initDate >= endDate) {
            errors.endHour = 'No puede ser inferior a la fecha de inicio';
        }
    }
    if (!values?.periodicy?.trim()) {
        errors.periodicy = 'Requerido';
    }
    if (!values?.amount && values?.periodicy !== 'Once') {
        errors.amount = 'Requerido';
    }
    if (values?.amount <= 1 && values?.periodicy !== 'Once') {
        errors.amount = 'Tiene que ser mayor que 1';
    }
    if (!values?.participants || values?.participants?.length <= 0) {
        errors.participants = 'Requerido';
        errors.participantsValidation = 'Requerido';
    }
    if (!values?.professional || !values?.professional?.id) {
        errors.professional = { id: 'Requerido' };
    }
    if (!values.relatedContract?.id) {
        errors.relatedContract = { id: 'Requerido' }
    }

    return errors
}

const ValidateEditAgenda = (values) => {
    const errors = {};

    let now = moment(new Date())
    let selected = moment(values?.startDate)
    if (selected < now) {
        errors.startDate = "La fecha no puede ser anterior a la actual"
    }

    return errors
}

const validateActivityEdit = (values) => {
    const errors = {};
    if (!values?.name?.trim()) {
        errors.name = 'Requerido';
    }
    if (!values?.description?.trim()) {
        errors.description = 'Requerido';
    }
    if (!values?.professional || !values?.professional?.id) {
        errors.professional = { id: 'Requerido' };
    }

    
    if (!values?.participants || values?.participants?.length <= 0) {
        errors.participants = 'Requerido';
        errors.participants = { id: 'Requerido' }
    }
    

   /*  if (values?.participants?.some(c => c === null || c === undefined)) {
        console.log(values?.participants)
        //errors.participants = 'Requerido';
        //errors.participantsValidation = 'Requerido';

        //errors.participants = 'Requerido';
        //errors.participantValidation = 'Requerido';
        //errors.participants = { id: 'Requerido' }
        const position = values?.participants?.findIndex(p => p === null || p === undefined);

        errors.participants = values.participants.map((participant, index) => ({
            ...errors.participants?.[index],
            id: index === position ? 'Requerido' : undefined,
        }));   
    }  */

    if (!values?.relatedContract?.id) {
        errors.relatedContract = { id: 'Requerido' }
    }

    return errors
}
const ActivitiesTitle = ({ record }) => {
    return <span>Actividad {record ? `${record?.name}` : ''}</span>;
};

const ActivitiesFilter = (props) => {
    const [languajeList, setlanguajeList] = useState()
    const [likeList, setlikeList] = useState()
    const [activityTypeList, setActivityTypeList] = useState()
    const [activitySubTypeList, setActivitySubTypeList] = useState()
    const [therapeuticalProfilesList, setTherapeuticalProfilesList] = useState()
    // const [clientTypes, setclientTypes] = useState()
    const chipClasses = ChipArrayUseStyles();
    const [workingCenter, setworkingCenter] = useState()
    const [professionalTypes, setprofessionalTypes] = useState()

    useEffect(() => {
        getLanguajes().then(
            res => {
                setlanguajeList(res?.elements);
            }
        )
        getLikes().then(
            res => {
                setlikeList(res?.elements);
            }
        )
        // getClientType().then(
        //     res => {
        //         setclientTypes(res.elements);
        //     }
        // )
        getWorkingCenter().then(
            res => {
                setworkingCenter(res?.elements);
            }
        )
        getActivityType().then(
            res => {
                setActivityTypeList(res?.elements);
            }
        )
        getActivityTypeValue().then(
            res => {
                setActivitySubTypeList(res?.elements);
            }
        )
        getTherapeuticProfile().then(
            res => {
                setTherapeuticalProfilesList(res?.elements);
            }
        )
        getProfessionalType().then(
            res => {
                setprofessionalTypes(res.elements);
            }
        )
    }, []);

    var filterType = ['SOCIAL_WORKER', 'PSYCHOLOGIST', 'TASOC', 'THERAPIST'];
    return (
        <Filter {...props}>
            <TextInput label="Nombre de actividad" source="name" alwaysOn />
            <TextInput label="Descripción" source="description" alwaysOn />
            <DateInput label="Fecha Desde" source="date" alwaysOn resettable={true} />
            <DateInput label="Fecha Hasta" source="endDate" alwaysOn resettable={true} />
            <ReferenceInput label="Responsable" source="profesionalId" reference="professional" allowEmpty={false} perPage={1000}
                filter={{ type: ['SOCIAL_WORKER', 'PSYCHOLOGIST', 'TASOC', 'THERAPIST'] }}
                filterToQuery={() => {
                    return { professionalTypeId: professionalTypes?.filter(item => filterType.includes(item.value)).map(item => item.id) }
                }}>
                <AutocompleteInput optionText={(record) => record?.identificationNumber + ' - ' + record?.name + ' ' + record?.surnames} resettable={true} />
            </ReferenceInput>
            <ReferenceInput label="Participante" source="clientId" reference="users" allowEmpty={false} perPage={1000}>
                <AutocompleteInput optionText={(record) => record?.identificationNumber + ' - ' + record?.name + ' ' + record?.surnames} resettable={true} />
            </ReferenceInput>
            {
                likeList ?
                    <AutocompleteArrayInput
                        label="Gustos/Preferencias"
                        source="clientsLikesId"
                        choices={likeList}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        className="full"
                        classes={chipClasses}
                        allowEmpty={false}
                    />
                    : null
            }
            {/* {
                clientTypes ?
                    <AutocompleteInput
                        label="Tipo usuario"
                        source="clientTypeId"
                        choices={clientTypes}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        classes={chipClasses}
                        resettable={true}
                    />
                    : null
            } */}
            {
                languajeList ?
                    <AutocompleteInput
                        label="Idioma"
                        source="languageId"
                        choices={languajeList}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        classes={chipClasses}
                        resettable={true}
                    />
                    : null
            }
            {
                workingCenter ?
                    <AutocompleteInput
                        label="Centro de Trabajo"
                        source="associatedWorkingCenterId"
                        choices={workingCenter}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        resettable={true}
                    />
                    : null
            }
            {
                activityTypeList ?
                    <AutocompleteArrayInput
                        label="Tipo de actividad"
                        source="primaryTypeList"
                        choices={activityTypeList}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        className="full"
                        classes={chipClasses}
                        allowEmpty={false}
                    />
                    : null
            }
            {
                activitySubTypeList ?
                    <AutocompleteArrayInput
                        label="Subtipo de actividad"
                        source="secondaryTypeList"
                        choices={activitySubTypeList}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        className="full"
                        classes={chipClasses}
                        allowEmpty={false}
                    />
                    : null
            }
            {
                therapeuticalProfilesList ?
                    <AutocompleteArrayInput
                        label="Perfil Terapéutico"
                        source="therapeuticalProfileIds"
                        choices={therapeuticalProfilesList}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        className="full"
                        classes={chipClasses}
                        allowEmpty={false}
                    />
                    : null
            }
            <ReferenceInput
                label="Contrato"
                source="contractId"
                reference="contracts"
                perPage={1000}
                resettable={true}
            >
                <AutocompleteInput optionText={(record) => record?.contractNumber} />
            </ReferenceInput>

        </Filter>
    );
}

export const ActivityList = props => {

    
    const { identity, loading } = useGetIdentity();
    const [languageList, setLanguageList] = useState();
    const [workingCenterList, setWorkingCenterList] = useState();

    const useStyles = makeStyles((theme) => ({
        paper: {
            border: '1px solid',
            padding: theme.spacing(1),
            backgroundColor: theme?.palette?.background?.paper,
        },
    }));
    const [activitiesList, setActivitiesList] = useState();
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        getLanguajes().then(
            res => {
                setLanguageList(res?.elements);
            }
        )
        getWorkingCenter().then(
            res => {
                setWorkingCenterList(res?.elements);
            }
        )
    }, []);

    
const customExporter = async (data, fetchRelatedRecords, dataProvider) => {
    const pageSize = 1000; 
    let currentPage = 2;
    let allData = [...data]; // Comenzamos con los datos ya cargados en la vista
    let hasMoreData = true;

    const variable = '';    

    if(data.length === pageSize){

        try {
            // Mientras haya más datos por cargar (cuando las páginas traen registros)
            while (hasMoreData) {
                const { data: pageData } = await dataProvider.getList('activities', {
                    filter: { ...variable },
                    pagination: { page: currentPage, perPage: pageSize },
                    sort: { field: 'id', order: 'ASC' },
                });
    
                allData = [...allData, ...pageData];
    
                if (pageData.length < pageSize) {
                    hasMoreData = false;
                }
    
                currentPage += 1;
    
                console.log(`Página ${currentPage - 1} descargada. Total de registros descargados: ${allData.length}`);
            }
    
            console.log('Exportación completa. Total de datos:', allData.length);
    
        } catch (error) {
            console.error('Error durante la exportación con paginación:', error);
        }
    }

    exporter(allData);

};

    const exporter = activities => {
        // if (!isOpen) {
        //     setIsOpen(true);
        // }
        setIsOpen(false);
        let activityList = activities.map(post => {
            const { id, icon, clientsLikesId, clientTypeId, secondaryTypeId, primaryTypeId, languageId, isDeleted, ...activityElement } = post;
            // activityElement.startDate = moment(activityElement.startDate[0]).utc().format('DD-MM-yyyy HH:mm');
            let startDates = [...activityElement?.startDate];
            let participants = [...activityElement?.participants];
            let professional = { ...activityElement?.professional };
            let contract = { ...activityElement?.relatedContract };
            activityElement.professional = professional?.name + " " + professional?.surnames;
            activityElement.participants = '';
            activityElement.contractNumber = contract?.contractNumber;
            participants.forEach((participant, index) => {
                let commaOrNot = index === participants?.length - 1 ? '' : ' , ';
                activityElement.participants += participant?.name + " " + participant?.surnames + commaOrNot;
            })
            activityElement.startDate = '';
            startDates.forEach((startDate, index) => {
                let commaOrNot = index === startDates?.length - 1 ? '' : ' , ';
                activityElement.startDate += moment(startDate).utc().format('DD-MM-yyyy HH:mm').toString() + commaOrNot;
            });
            if (activityElement.languageId) {
                let language = languageList?.filter(language => language?.id === activityElement?.languageId);
                if (language && language?.length > 0) {
                    activityElement.languageId = language[0]?.value;
                }
            }
            if (activityElement.associatedWorkingCenterId) {
                let workingCenter = workingCenterList.filter(workingcenter => workingcenter?.id === activityElement?.associatedWorkingCenterId);
                if (workingCenter && workingCenter?.length > 0) {
                    activityElement.associatedWorkingCenterId = workingCenter[0]?.value;
                }
            }
            return activityElement;
        });
        activityList = translateHeaders(activityList);
        setActivitiesList(activityList);
        const xlsxBlob = arrayToXlsx(activityList);
        saveAs(xlsxBlob, 'actividades.xlsx');
    };

    const saveExcelFile = () => {
        const xlsxBlob = arrayToXlsx(activitiesList);
        saveAs(xlsxBlob, 'actividades.xlsx');
    };

    // const savePdfFile = () => {
    //     const unit = "pt";
    //     const size = "A4"; // Use A1, A2, A3 or A4
    //     const orientation = "landscape"; // portrait or landscape

    //     const marginLeft = 40;
    //     const doc = new jsPDF(orientation, unit, size);

    //     doc.setFontSize(12);

    //     const title = "Actividades";
    //     const headers = [["Nombre", "Descripción", "Responsable", "Fecha Actividad", "Participante(s)", "Centro Trabajo", "Recursiva"]];

    //     const data = activitiesList.map(elt => [elt['Nombre'], elt['Descripción'], elt['Responsable'], elt['Fecha Actividad'],
    //     elt['Participante(s)'], elt['Centro Trabajo'], elt['Recursiva']]);

    //     let content = {
    //         startY: 50,
    //         head: headers,
    //         body: data
    //     };

    //     doc.text(title, marginLeft, 40);
    //     doc.autoTable(content);
    //     doc.save("actividades.pdf")
    // };

    const saveCsvFile = () => {
        jsonExport(activitiesList, {
            headers: ["Nombre", "Descripción", "Responsable", "Fecha Actividad", "Participante(s)", "Centro Trabajo", "Recursiva"] // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'actividades');
        });
    };

    const arrayToXlsx = (array) => {
        const worksheet = XLSX.utils.json_to_sheet(array);
        worksheet["!cols"] = [{ wch: 30 }, { wch: 40 }, { wch: 26 }, { wch: 16 }, { wch: 35 }, /*{ wch: 10 },*/ { wch: 14 }, { wch: 10 }];
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const xlsxBlob = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        return new Blob([xlsxBlob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
    };
    const translateHeaders = (activitiesList) => {
        for (let i = 0; i < activitiesList?.length; i++) {
            activitiesList[i]['Nombre'] = activitiesList[i]['name'];
            delete activitiesList[i]['name'];
            activitiesList[i]['Descripción'] = activitiesList[i]['description'];
            delete activitiesList[i]['description'];
            activitiesList[i]['Responsable'] = activitiesList[i]['professional'];
            delete activitiesList[i]['professional'];
            activitiesList[i]['Fecha Actividad'] = activitiesList[i]['startDate'];
            delete activitiesList[i]['startDate'];
            activitiesList[i]['Participante(s)'] = activitiesList[i]['participants'];
            delete activitiesList[i]['participants'];
            // activitiesList[i]['Idioma'] = activitiesList[i]['languageId'];
            // delete activitiesList[i]['languageId'];
            activitiesList[i]['Centro Trabajo'] = activitiesList[i]['associatedWorkingCenterId'];
            delete activitiesList[i]['associatedWorkingCenterId'];
            activitiesList[i]['Recursiva'] = activitiesList[i]['scheduledActivitiesId'].length > 1 ? "Si" : "No";
            delete activitiesList[i]['scheduledActivitiesId'];
            delete activitiesList[i]['location'];
            delete activitiesList[i]['comments'];
            delete activitiesList[i]['isRecord'];
            activitiesList[i]['Tipo'] = activitiesList[i]['primaryTypeDescription'];
            delete activitiesList[i]['primaryTypeDescription'];
            activitiesList[i]['SubTipo'] = activitiesList[i]['secondaryTypeDescription'];
            delete activitiesList[i]['secondaryTypeDescription'];
            activitiesList[i]['Contrato'] = activitiesList[i]['contractNumber'];
            delete activitiesList[i]['contractNumber'];
            delete activitiesList[i]['relatedContract'];
            activitiesList[i]['Estado'] = activitiesList[i]['status'];
            delete activitiesList[i]['status'];
        }
        return activitiesList;
    }

    if (loading && !identity) {
        return <div></div>;
    }
    return (
        // <div onClick={() => setIsOpen(false)}>
        <>
            <List bulkActionButtons={props?.permissions?.activities?.delete ? <PostBulkActionButtonsPermision perm="activities.delete" /> : false} filters={<ActivitiesFilter />} exporter={identity.roles === "CONTRACTING_AUTHORITY" ? false : (data, fetchRelatedRecords, dataProvider) => customExporter(data, fetchRelatedRecords, dataProvider)} {...props}>
                <Datagrid rowClick={props?.permissions?.activities?.edit ? "edit" : "show"} isRowSelectable={record => !record?.isDeleted && props?.permissions?.activities?.delete}>
                    <TextField label="Nombre Actividad" source="name" />
                    <TextField label="Descripción" source="description" />
                    <TextField label="Responsable" source="professional.name" sortable={false} />
                    <FunctionField style={{ whiteSpace: 'nowrap' }} label="Fecha Actividad"
                        className="capitalize" sortable={true} sortBy="ScheduledActivities.StartDate"
                        render={record => ReactHtmlParser(record?.startDate?.map(d => parseDate(d)).slice(0, 3).join('<br>'))}
                    />
                    <FunctionField style={{ width: 150 }} label="Participante(s)" className="capitalize" sortable={true}
                        sortBy="Participants.Name"
                        render={record => {
                            let arr = record?.participants?.map(p => `${p?.name} ${p?.surnames}`)
                            return ReactHtmlParser(arr?.slice(0, 3).join('<br>') + (arr?.length > 3 ? '...' : ''))
                        }}
                    />
                    <WorkingCenterField label="Centro de Trabajo" source="associatedWorkingCenterId" sortable={false} />
                    <TextField label="Tipo" source="primaryTypeDescription" />
                    <TextField label="SubTipo" source="secondaryTypeDescription" />
                    <TextField label="Contrato" source="relatedContract.contractNumber" />
                    <TextField label="Estado" source="status" sortable={false} />
                    <FunctionField style={{ whiteSpace: 'nowrap' }} label="Recursiva" className="capitalize" render={record => record?.scheduledActivitiesId?.length > 1 ? 'Si' : 'No'} />
                    {
                        identity.roles === "SUPER_ADMIN" ? <FunctionField label="Eliminada" render={record => `${record?.isDeleted ? "SI" : ""}`} /> : null
                    }
                </Datagrid>
            </List>
            {isOpen && (
                <div style={{ position: 'fixed', top: '6rem', right: '6rem', zIndex: 1000 }}
                    id="tooltip"
                    className={classes.paper}
                >
                    <MUIButton variant="outlined" color="secondary" onClick={() => saveExcelFile()}>Excel</MUIButton>
                    <MUIButton variant="outlined" color="secondary" onClick={() => saveCsvFile()}>CSV</MUIButton>
                    {/* <MUIButton variant="outlined" color="secondary" onClick={() => savePdfFile()}>PDF</MUIButton> */}
                </div>
            )}
        </>
    )
};


export const ActivityEdit = (props) => {

    return (
        <Edit title={<ActivitiesTitle />} mutationMode="pessimistic" {...props} transform={transformActivitiesUpdate}>
            {ActivityForm(props, 'edit')}
        </Edit>
    )
};

export const ActivityCreate = (props) => {

    return (
        <Create mutationMode="pessimistic" {...props} transform={transformActivitiesCreate}>
            {ActivityForm(props)}
        </Create>
    )
};

export const ActivityShow = props => (
    <Show title={<ActivitiesTitle />} {...props}>
        {ActivityShowForm(props)}
    </Show>
);

const ActivityForm = (props, mode = 'create') => {
    const [languajeList, setlanguajeList] = useState()
    const [workingCenter, setworkingCenter] = useState();
    const [activityType, setactivityType] = useState();
    const [likeList, setlikeList] = useState()
    // const [clientTypes, setclientTypes] = useState()
    const [iconList, seticonList] = useState()
    const classes = TabsUseStyles();
    const chipClasses = ChipArrayUseStyles();
    const [professionalTypes, setprofessionalTypes] = useState()

    useEffect(() => {
        getLanguajes().then(
            res => {
                setlanguajeList(res.elements);
            }
        )
        getLikes().then(
            res => {
                setlikeList(res.elements);
            }
        )
        getClientType().then(
            res => {
                // setclientTypes(res.elements);
            }
        )
        getWorkingCenter().then(
            res => {
                setworkingCenter(res.elements);
            }
        )
        getActivityType().then(
            res => {
                setactivityType(res.elements);
            }
        )
        getProfessionalType().then(
            res => {
                setprofessionalTypes(res.elements);
            }
        )
        seticonList(iconListFont)

    }, []);

    const redirect = useRedirect();
    const notify = useNotify()
    const [mutate] = useMutation();
    const { identity, loading } = useGetIdentity();
    const save = useCallback(
        async (values) => {
            try {
                const res = await mutate({
                    type: mode === 'create' ? 'create' : 'update',
                    resource: 'activities',
                    payload: { data: mode === 'create' ? transformActivitiesCreate(values) : transformActivitiesUpdate(values) },
                }, { returnPromise: true });
                notify(`ra.notification.${mode === 'create' ? 'created' : 'updated_item'}`)
                if (mode === 'create') {
                    redirect('edit', props?.basePath, res?.data?.id, res?.data)
                } else {
                    redirect('list', props?.basePath)
                }
            } catch (error) {
                if (error?.error && error?.error?.code === 4003) {
                    notify(error?.error?.message, 'error')
                    // return {
                    //     professional: { id: error.error.message }
                    // };
                }
                if (error?.error && error?.error?.code === 4001) {
                    notify(error?.error?.message, 'error')
                    // return {
                    //     contractNumber: error.error.message
                    // };
                }
                //notify('ra.notification.http_error', 'warning')
            }
        },
        [mutate, mode, notify, props.basePath, redirect],
    );


    const CustomDatagridRow = ({ record, resource, id, onToggleItem, children, selected, basePath }) => (
        <TableRow key={id}>
            {React.Children.map(children, field => (
                <TableCell style={{ width: field?.props?.width }} key={`${id}-${field?.props?.source}`}>
                    {React.cloneElement(field, {
                        record,
                        basePath,
                        resource,
                    })}
                </TableCell>
            ))}
        </TableRow>
    );

    const CustomDatagridBody = props => <DatagridBody {...props} row={<CustomDatagridRow />} />;
    const CustomDatagrid = props => <Datagrid {...props} body={<CustomDatagridBody />} />;
    if (loading && !identity) {
        return <div></div>;
    }
    return (
        <TabbedForm toolbar={<CustomToolbarPermissions perm="activities.delete" />} classes={classes} save={save} validate={mode === 'create' ? validateActivityCreate : validateActivityEdit}>
            <FormTab label="Actividad" contentClassName="tab-container">
                <TextInput label="Nombre Actividad" source="name" />
                <TextInput label="Descripción" source="description" />
                <ReferenceInput
                    label="Contrato"
                    source="relatedContract.id"
                    reference="contracts"
                    perPage={1000}
                    filterToQuery={(value) => {
                        return { nameINumber: value }
                    }}
                >
                    <AutocompleteInput optionText={(record) => record?.contractNumber} resettable={true} />
                </ReferenceInput>
                <ContractProfessionalsInput professionalTypes={professionalTypes} source="professional.id"></ContractProfessionalsInput>
                {/* <TextInput label="Icono" source="icon" /> */}
                {
                    iconList ?
                        <AutocompleteInput
                            label="Icono"
                            source="icon"
                            choices={iconList}
                            // optionText="value"
                            optionText={<OptionIconRenderer />}
                            inputText={choice => `${choice?.value}`}
                            matchSuggestion={(filterValue, suggestion) => {
                                return suggestion ? suggestion?.value?.includes(filterValue) : false
                            }}
                            optionValue="value"
                            // resettable={true}
                            suggestionLimit={25}
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                        />
                        : null
                }
                {
                    likeList ?
                        <AutocompleteArrayInput
                            label="Gustos/Preferencias"
                            source="clientsLikesId"
                            choices={likeList}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            className="full"
                            classes={chipClasses}
                        />
                        : null
                }
                {/* {
                    clientTypes ?
                        <AutocompleteInput
                            label="Perfil de usuario"
                            source="clientTypeId"
                            choices={clientTypes}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            classes={chipClasses}
                            resettable={true}
                        />
                        : null
                } */}
                {
                    languajeList ?
                        <AutocompleteInput
                            label="Idioma"
                            source="languageId"
                            choices={languajeList}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                        />
                        : null
                }
                {
                    workingCenter ?
                        <AutocompleteInput
                            label="Centro de Trabajo"
                            source="associatedWorkingCenterId"
                            choices={workingCenter}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' }, autoComplete: 'off' }}
                            resettable={true}
                        />
                        : null
                }
                {
                    activityType ?
                        <AutocompleteInput
                            label="Tipología dinámica"
                            source="primaryTypeId"
                            choices={activityType}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' }, autoComplete: 'off' }}
                            resettable={true}
                            validate={[required()]}
                        />
                        : null
                }
                <ActivityTypeValueInput source="typeValue" mode={mode}></ActivityTypeValueInput>
            </FormTab>
            <FormTab label="Participantes">
                <ContractParticipantsInput source="id" mode={mode} ></ContractParticipantsInput>
                <TextInput style={{ display: 'none' }} source="participantsValidation" />
            </FormTab>
            {
                mode === 'edit' ?
                    <FormTab label="Agenda" contentClassName="">
                        <ReferenceManyField
                            className="full"
                            pagination={<Pagination />}
                            addLabel={false}
                            target="id"
                            reference="scheduledActivities"
                            perPage={10}
                        >
                            <CustomDatagrid className="full">
                                <TextField width="25%" label="Nombre actividad" source="activity.name" />
                                {/* <DateField label="Fecha inicio" source="startDate" /> */}
                                <FunctionField width="15%" label="Fecha inicio" className="capitalize" render={record => parseDate(record?.startDate)} />
                                <FunctionField width="15%" label="Fecha Fin" className="capitalize" render={record => parseDate(record?.endDate)} />
                                <span></span>
                                <FunctionField width="15%" label="Estado" className="state" render={record => {
                                    return record.state === record?.isHeld ? "En curso" : record?.isCancel ? "Cancelada" : !!record?.realEndDate ? "Finalizada" : "Pendiente"
                                }} />
                                {
                                    identity.roles === "SUPER_ADMIN" ?
                                        <BooleanField width="2rem" label="Eliminada" source="isDeleted" /> :
                                        <span></span>
                                }

                                {/* <BooleanField label="Cancelada" source="isCancel" />
                                <BooleanField label="Comenzada" source="isHeld" />
                                <FunctionField label="Finalizada" render={record => {
                                    return !!record.realEndDate ? <Done color="primary" /> : <Clear />
                                }} /> */}
                                <FunctionField label="" render={record => {
                                    return !record?.isDeleted && !record?.isCancel && !record?.isHeld && !record?.realEndDate && props?.permissions && props?.permissions?.activities?.edit ?
                                        <>
                                            <EditScheduledActivityButton record={record} identity={identity} loading={loading} />
                                        </> : null
                                }} />
                                <FunctionField label="" render={record => {
                                    return !record?.isDeleted && record?.isHeld && record?.realEndDate && props?.permissions && props?.permissions?.activities?.edit ?
                                        <>
                                            < EditActivityCommentButton record={record} identity={identity} loading={loading} />
                                        </> : null
                                }} />
                                <FunctionField label="" render={record => {
                                    return !record?.isDeleted && !record?.isCancel && !record?.isHeld && !record?.realEndDate && props?.permissions && props?.permissions?.activities?.delete ? <DeleteScheduledActivityButton record={record} identity={identity} loading={loading} /> : null
                                }} />
                            </CustomDatagrid>
                        </ReferenceManyField >
                    </FormTab>
                    :
                    <FormTab label="Periodicidad" contentClassName="tab-container">
                        <DateInput label="Fecha de inicio" source="initDate" validate={[required()]}></DateInput>
                        <div style={{ width: '98%' }}>
                            <TextInput style={{ marginRight: 5 }} label="Hora inicio (HH:MM)" source="initHour" validate={[required()]} ></TextInput>
                            <TextInput style={{ marginLeft: 5 }} label="Hora fin (HH:MM)" source="endHour" validate={[required()]} ></TextInput>
                        </div>
                        <SelectInput label="Periodicidad" source="periodicy" choices={[
                            { id: 'Once', name: 'Una vez' },
                            { id: 'EveryWeek', name: 'Cada semana' },
                            { id: 'EveryMonth', name: 'Cada Mes' },
                            { id: 'EveryYear', name: 'Cada año' },
                        ]} required />
                        <FormDataConsumer>
                            {({ formData, ...rest }) => formData?.periodicy !== 'Once' &&
                                <NumberInput label="Cantidad de eventos" source="amount" required></NumberInput>
                            }
                        </FormDataConsumer>
                    </FormTab>
            }
        </TabbedForm >
    )
}

const ActivityShowForm = (props, mode = 'show') => {
    const [languajeList, setlanguajeList] = useState()
    const [workingCenter, setworkingCenter] = useState();
    const [activityType, setactivityType] = useState();
    const [likeList, setlikeList] = useState()
    // const [clientTypes, setclientTypes] = useState()
    const [iconList, seticonList] = useState()
    const classes = TabsUseStyles();
    const chipClasses = ChipArrayUseStyles();
    const [professionalTypes, setprofessionalTypes] = useState()



    useEffect(() => {
        getLanguajes().then(
            res => {
                setlanguajeList(res.elements);
            }
        )
        getLikes().then(
            res => {
                setlikeList(res.elements);
            }
        )
        getClientType().then(
            res => {
                // setclientTypes(res.elements);
            }
        )
        getWorkingCenter().then(
            res => {
                setworkingCenter(res.elements);
            }
        )
        getActivityType().then(
            res => {
                setactivityType(res.elements);
            }
        )
        getProfessionalType().then(
            res => {
                setprofessionalTypes(res.elements);
            }
        )
        seticonList(iconListFont)

    }, []);

    const redirect = useRedirect();
    const notify = useNotify()
    const [mutate] = useMutation();
    const { identity, loading } = useGetIdentity();
    const save = useCallback(
        async (values) => {
            try {
                const res = await mutate({
                    type: mode === 'create' ? 'create' : 'update',
                    resource: 'activities',
                    payload: { data: mode === 'create' ? transformActivitiesCreate(values) : transformActivitiesUpdate(values) },
                }, { returnPromise: true });
                notify(`ra.notification.${mode === 'create' ? 'created' : 'updated_item'}`)
                if (mode === 'create') {
                    redirect('edit', props?.basePath, res?.data?.id, res.data)
                } else {
                    redirect('list', props?.basePath)
                }
            } catch (error) {
                if (error?.error && error?.error?.code === 4003) {
                    notify(error?.error?.message, 'error')
                    return {
                        professional: { id: error?.error?.message }
                    };
                }
                if (error?.error && error?.error?.code === 4001) {
                    notify(error?.error?.message, 'error')
                    return {
                        contractNumber: error?.error?.message
                    };
                }
                notify('ra.notification.http_error', 'warning')
            }
        },
        [mutate, mode, notify, props.basePath, redirect],
    );

    const CustomDatagridRow = ({ record, resource, id, onToggleItem, children, selected, basePath }) => (
        <TableRow key={id}>
            {React.Children.map(children, field => (
                <TableCell style={{ width: field?.props?.width }} key={`${id}-${field?.props?.source}`}>
                    {React.cloneElement(field, {
                        record,
                        basePath,
                        resource,
                    })}
                </TableCell>
            ))}
        </TableRow>
    );

    const CustomDatagridBody = props => <DatagridBody {...props} row={<CustomDatagridRow />} />;
    const CustomDatagrid = props => <Datagrid {...props} body={<CustomDatagridBody />} />;

    return (
        <TabbedForm toolbar={null} classes={classes} save={save} validate={mode === 'create' ? validateActivityCreate : validateActivityEdit}>
            <FormTab label="Actividad" contentClassName="tab-container">
                <TextInput label="Nombre Actividad" source="name" />
                <TextInput label="Descripción" source="description" />
                <ReferenceInput
                    label="Contrato"
                    source="relatedContract.id"
                    reference="contracts"
                    perPage={1000}
                    filterToQuery={(value) => {
                        return { nameINumber: value }
                    }}
                >
                    <AutocompleteInput optionText={(record) => record?.contractNumber} resettable={true} />
                </ReferenceInput>
                <ContractProfessionalsInput professionalTypes={professionalTypes} source="professional.id" disabled="true"></ContractProfessionalsInput>
                {/* <TextInput label="Icono" source="icon" /> */}
                {
                    iconList ?
                        <AutocompleteInput
                            label="Icono"
                            source="icon"
                            choices={iconList}
                            // optionText="value"
                            optionText={<OptionIconRenderer />}
                            inputText={choice => `${choice?.value}`}
                            matchSuggestion={(filterValue, suggestion) => {
                                return suggestion ? suggestion?.value?.includes(filterValue) : false
                            }}
                            optionValue="value"
                            // resettable={true}
                            suggestionLimit={25}
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                        />
                        : null
                }
                {
                    likeList ?
                        <AutocompleteArrayInput
                            label="Gustos/Preferencias"
                            source="clientsLikesId"
                            choices={likeList}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            className="full"
                            classes={chipClasses}
                        />
                        : null
                }
                {/* {
                    clientTypes ?
                        <AutocompleteInput
                            label="Perfil de usuario"
                            source="clientTypeId"
                            choices={clientTypes}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            classes={chipClasses}
                            resettable={true}
                        />
                        : null
                } */}
                {
                    languajeList ?
                        <AutocompleteInput
                            label="Idioma"
                            source="languageId"
                            choices={languajeList}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                        />
                        : null
                }
                {
                    workingCenter ?
                        <AutocompleteInput
                            label="Centro de Trabajo"
                            source="associatedWorkingCenterId"
                            choices={workingCenter}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' }, autoComplete: 'off' }}
                            resettable={true}
                        />
                        : null
                }
                {
                    activityType ?
                        <AutocompleteInput
                            label="Tipología dinámica"
                            source="primaryTypeId"
                            choices={activityType}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' }, autoComplete: 'off' }}
                            resettable={true}
                        />
                        : null
                }
                <ActivityTypeValueInput source="typeValue" mode={mode}></ActivityTypeValueInput>
            </FormTab>
            <FormTab label="Participantes">
                <ContractParticipantsInput source="id" mode={mode} ></ContractParticipantsInput>
                <TextInput style={{ display: 'none' }} source="participantsValidation" />
            </FormTab>
            {
                mode === 'edit' ?
                    <FormTab label="Agenda" contentClassName="">
                        <ReferenceManyField
                            pagination={<Pagination />}
                            addLabel={false}
                            target="id"
                            reference="scheduledActivities"
                            perPage={10}
                        >
                            <CustomDatagrid>
                                <TextField width="2rem" label="Nombre actividad" source="activity.name" />
                                {/* <DateField label="Fecha inicio" source="startDate" /> */}
                                <FunctionField label="Fecha inicio" className="capitalize" render={record => parseDate(record?.startDate)} />
                                <span></span>
                                <FunctionField style={{ width: "2rem" }} label="Estado" className="state" render={record => {
                                    return record.state === record?.isHeld ? "En curso" : record?.isCancel ? "Cancelada" : !!record?.realEndDate ? "Finalizada" : "Pendiente"
                                }} />
                                <span></span>
                                {/* <BooleanField label="Cancelada" source="isCancel" />
                                <BooleanField label="Comenzada" source="isHeld" />
                                <FunctionField label="Finalizada" render={record => {
                                    return !!record.realEndDate ? <Done color="primary" /> : <Clear />
                                }} /> */}
                                <FunctionField label="" render={record => {
                                    return !record?.isCancel && !record?.isHeld && !record?.realEndDate && props?.permissions && props?.permissions?.activities?.edit ? <EditScheduledActivityButton record={record} identity={identity} loading={loading} /> : null
                                }} />
                                <FunctionField label="" render={record => {
                                    return !record?.isCancel && !record?.isHeld && !record?.realEndDate && props?.permissions && props?.permissions?.activities?.delete ? <DeleteScheduledActivityButton record={record} identity={identity} loading={loading} /> : null
                                }} />
                            </CustomDatagrid>
                        </ReferenceManyField >
                    </FormTab>
                    :
                    <FormTab label="Periodicidad" contentClassName="tab-container">
                        <DateInput label="Fecha de inicio" source="initDate"></DateInput>
                        <div style={{ width: '98%' }}>
                            <TextInput style={{ marginRight: 5 }} label="Hora inicio (HH:MM)" source="initHour" ></TextInput>
                            <TextInput style={{ marginLeft: 5 }} label="Hora fin (HH:MM)" source="endHour" ></TextInput>
                        </div>
                        <SelectInput label="Periodicidad" source="periodicy" choices={[
                            { id: 'Once', name: 'Una vez' },
                            { id: 'EveryWeek', name: 'Cada semana' },
                            { id: 'EveryMonth', name: 'Cada Mes' },
                            { id: 'EveryYear', name: 'Cada año' },
                        ]} />
                        <FormDataConsumer>
                            {({ formData, ...rest }) => formData?.periodicy !== 'Once' &&
                                <NumberInput label="Cantidad de eventos" source="amount"></NumberInput>
                            }
                        </FormDataConsumer>
                    </FormTab>
            }
        </TabbedForm>
    )
}

const EditScheduledActivityButton = (props) => {
    const [showDialog, setshowDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const record = useRecordContext(props);

    const location = useLocation();
    const refresh = useRefresh();
    const form = useForm();
    const notify = useNotify();
    const [edit] = useUpdate('scheduledActivities');


    const handlePopOverClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleCloseClick = () => {
        setshowDialog(false);
    };

    const handleClick = (event) => {
        if (form?.getState()?.dirty) {
            handlePopOverClick(event)
        } else {
            setshowDialog(true);
        }
    };

    const handleSubmit = async values => {
        edit(
            { payload: { data: transformScheduledActivityEdit(values) } },
            {
                onSuccess: ({ data }) => {
                    setshowDialog(false);
                    refresh();
                },
                onFailure: ({ error }) => {
                    notify(error?.message, 'error');
                }
            }
        );
    };

    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme?.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme?.spacing(1),
            top: theme?.spacing(1),
            color: theme?.palette?.grey[500],
        },
    });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes?.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes?.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        !props.identity ? null : <>
            <Button
                onClick={handleClick}
                label="Editar agenda"
                icon="card-account-mail"
                disabled={props?.identity?.roles === "CONTRACTING_AUTHORITY"}
            >
                <CalendarIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: 10 }}>Para poder editar contactos es necesario que guarde la información del formulario antes.</Typography>
            </Popover>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle onClose={handleCloseClick}>Editar Agenda</DialogTitle>
                <DialogContent>
                    <SimpleForm
                        save={handleSubmit}
                        validate={ValidateEditAgenda}
                        redirect={location?.pathname}
                        mode="edit"
                        loading={props?.loading}
                        handleCloseClick={handleCloseClick}
                        initialValues={record}
                    >
                        <DateTimeInput label="Fecha de Inicio" source="startDate"></DateTimeInput>
                        <DateTimeInput label="Fecha de Fin" source="endDate"></DateTimeInput>
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
}

const EditActivityCommentButton = (props) => {
    const [showDialog, setshowDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const record = useRecordContext(props);
    const form = useForm();

    const [interactionList, setInteractionList] = useState(record?.interactions);
    const [generalComment, setGeneralComment] = useState();
    const [tabValue, setTabValue] = useState(0);
    const [databaseOperationInProgress, setDataBaseOperationInProgress] = useState(false);

    const handlePopOverClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event, record) => {
        if (form?.getState().dirty) {
            handlePopOverClick(event)
        } else {
            record.interactions.forEach(element => {
                try {
                    element.comment = element?.comment ? atob(element?.comment) : null;
                    element.technicalComment = element?.technicalComment ? atob(element?.technicalComment) : null;
                    element.commentForRelative = element?.commentForRelative ? atob(element?.commentForRelative) : null;
                } catch (ex) { }
            });
            setInteractionList(record?.interactions);
            setGeneralComment(record?.comments);
            setshowDialog(true);
        }
    };

    const onChangeGeneralComment = (value) => {
        setGeneralComment(value);
    };

    const onChangeComment = (value, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].comment = value;
        record.interactions[index].comment = value;
        setInteractionList(interactionListClone);
    };

    const onChangeTechComm = (value, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].technicalComment = value;
        record.interactions[index].technicalComment = value;
        setInteractionList(interactionListClone);
    };

    const onChangeRelativesComm = (value, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].commentForRelative = value;
        record.interactions[index].commentForRelative = value;
        setInteractionList(interactionListClone);
    };

    const onStarClickMood = (nextValue, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].mood = nextValue;
        record.interactions[index].mood = nextValue;
        setInteractionList(interactionListClone);
    };

    const onStarClickHealth = (nextValue, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].healthCondition = nextValue;
        record.interactions[index].healthCondition = nextValue;
        setInteractionList(interactionListClone);
    };
    const onStarClickFeedback = (nextValue, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].feedbackMark = nextValue;
        record.interactions[index].feedbackMark = nextValue;
        setInteractionList(interactionListClone);
    };

    // const handleSubmit = async values => {
    //     edit(
    //         {payload: {data: transformScheduledActivityEdit(values) } },
    //         {
    //             onSuccess: ({data}) => {
    //                 setshowDialog(false);
    //                 // Update the comment form to target the newly created post
    //                 // Updating the ReferenceInput value will force it to reload the available posts
    //                 // refresh();
    //                 refresh();
    //             },
    //             onFailure: ({error}) => {
    //                 notify(error.message, 'error');
    //             }
    //         }
    //     );
    // };

    const handleCloseClick = async () => {

        var updatedInteractionList = [];
        interactionList.forEach(element => {
            element.comment = element?.comment ? btoaReplacement(element?.comment) : null;
            element.technicalComment = element?.technicalComment ? btoaReplacement(element?.technicalComment) : null;
            element.commentForRelative = element?.commentForRelative ? btoaReplacement(element?.commentForRelative) : null;
            if (!element?.mood) {
                element.mood = 0;
            }
            if (!element?.feedbackMark) {
                element.feedbackMark = 0;
            }
            if (!element?.healthCondition) {
                element.healthCondition = 0;
            }
            var updatedInteraction = new InteractionDto();
            Object.keys(element).forEach(e => {
                if (e === "creationDate") {
                    updatedInteraction[e] = null;
                } else {
                    updatedInteraction[e] = element[e];
                }
            });
            updatedInteractionList.push(updatedInteraction);
        });
        if (generalComment) {
            record.comments = generalComment;
        }
        setDataBaseOperationInProgress(true);
        //await scheduledActivityClient.scheduledActivityPut(transformScheduledActivityEdit(record));
        var success = false
        Promise.all([
            scheduledActivityClient?.comments({ id: record?.id, comments: generalComment }),
            interactionsClient?.many(updatedInteractionList)
        ]).then(() => {
            success = true
        }).finally(() => {
            setDataBaseOperationInProgress(false);
            updatedInteractionList.forEach((element) => {
                element.comment = element?.comment ? atob(element?.comment) : null;
                element.technicalComment = element?.technicalComment ? atob(element?.technicalComment) : null;
                element.commentForRelative = element?.commentForRelative ? atob(element?.commentForRelative) : null;
            })
            setInteractionList(updatedInteractionList)
            if (success) {
                record.interactions = updatedInteractionList
                setshowDialog(false);
            }
        })
    };
    function changeTabValue(index) {
        setTabValue(index);
        console.warn("changeTab " + index);
    }

    const handleCloseDialog = () => {
        setshowDialog(false);
    }

    if (props?.loading && !props?.identity) {
        return <div></div>;
    }

    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme?.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme?.spacing(1),
            top: theme?.spacing(1),
            color: theme?.palette?.grey[500],
        },
    });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes?.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes?.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        !props?.identity ? null :
            <>
                <Button
                    onClick={(event) => handleClick(event, record)}
                    label="Editar feedback"
                    icon="card-account-mail"
                    disabled={props?.identity?.roles === "CONTRACTING_AUTHORITY"}
                >
                    <AccountCircleIcon />
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopOverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography style={{ padding: 10 }}>Para poder editar contactos es necesario que guarde la información del formulario antes.</Typography>
                </Popover>
                {/* <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={handleCloseClick}
                >
                    <DialogTitle onClose={handleCloseClick}>Editar Comentario</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            save={handleSubmit}
                            validate={false}
                            redirect={location.pathname}
                            mode="edit"
                            loading={props.loading}
                            handleCloseClick={handleCloseClick}
                            initialValues={record}>
                            <RichTextInput label="" source="comments" validate={validateComment} />
                        </SimpleForm>
                    </DialogContent>
                </Dialog> */}
                <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={handleCloseDialog}
                    scroll="body"
                >
                    <DialogTitle>Feedback de la Sesión</DialogTitle>
                    <DialogContent>
                        {(interactionList && interactionList?.length > 0) ?
                            <div>
                                <label style={{ fontSize: '0.7rem' }}>Comentario</label>
                                <ReactQuill label="Comentario" value={generalComment} onChange={(val) => onChangeGeneralComment(val)} className='generalCommentQuill' />
                                <MUITabs value={tabValue}>
                                    {interactionList.map((interaction, index) =>
                                        <MUITab label={interaction?.client?.name + " " + interaction?.client?.surnames} onClick={() => changeTabValue(index)} />
                                    )}
                                </MUITabs>
                                {interactionList.map((interaction, index) =>
                                    <div
                                        role="tabpanel"
                                        hidden={tabValue !== index}>
                                        <div style={{ display: 'flex', marginTop: "0.5rem" }}>
                                            <label style={{ fontSize: '0.7rem', alignSelf: 'flex-start' }}>Detalles
                                            </label>
                                            <label style={{ fontSize: '0.7rem', position: 'relative', left: '29.5rem' }}>Juicio técnico
                                            </label>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <ReactQuill className="commentQuill" value={interaction?.comment} onChange={(val) => onChangeComment(val, index)} style={{ width: '47%' }} />
                                            <ReactQuill className="commentQuill" value={interaction?.technicalComment} onChange={(val) => onChangeTechComm(val, index)} style={{ width: '47%' }} />
                                        </div>
                                        {/* <div style={{ display: 'flex', marginTop: "0.5rem" }}> */}
                                        <label style={{ fontSize: '0.7rem', marginTop: '0.5rem', alignSelf: 'flex-start' }}>Información visible por terceras partes
                                        </label>
                                        {/* </div> */}
                                        {/* <div style={{ display: 'flex', justifyContent: 'center' }}> */}
                                        <ReactQuill label="Información visible por terceras partes" className="generalCommentQuill" value={interaction?.commentForRelative} onChange={(val) => onChangeRelativesComm(val, index)} style={{ width: '100%' }} />
                                        {/* </div> */}
                                        <div style={{ display: 'flex' }}>
                                            <Labeled label="Estado de ánimo">
                                                <StarPicker style={{ marginTop: 10 }} onChange={(val) => onStarClickMood(val, index)} value={interaction?.mood}
                                                    numberStars={6} doubleTapResets size={30} />
                                            </Labeled>
                                            <Labeled label="Feedback sesión">
                                                <StarPicker style={{ marginTop: 10, margingLeft: '3 rem' }} onChange={(val) => onStarClickFeedback(val, index)}
                                                    value={interaction?.feedbackMark} numberStars={6} doubleTapResets size={30} />
                                            </Labeled>
                                            <Labeled label="Estado físico">
                                                <StarPicker style={{ marginTop: 20, margingLeft: '3 rem' }} onChange={(val) => onStarClickHealth(val, index)}
                                                    value={interaction?.healthCondition} numberStars={6} doubleTapResets size={30} />
                                            </Labeled>
                                        </div>
                                    </div>
                                )}
                            </div> : <p>No ha habido asistentes a esta reunión</p>
                        }
                        {databaseOperationInProgress ? <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}><CircularProgress /> Por favor espere</Box> : null}
                    </DialogContent>
                    <DialogActions>
                        <MUIButton
                            style={{ backgroundColor: '#2196f3' }}
                            label="Cancelar"
                            onClick={handleCloseDialog}
                        >
                            Cancelar
                        </MUIButton>
                        <MUIButton
                            style={{ backgroundColor: '#2196f3' }}
                            label="Guardar"
                            onClick={handleCloseClick}
                        >
                            Guardar
                        </MUIButton>
                    </DialogActions >
                </Dialog >
            </>
    )
}

const DeleteScheduledActivityButton = ({ record }) => {
    const location = useLocation();
    const form = useForm();
    const [anchorEl, setAnchorEl] = useState(null)

    const handlePopOverClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (form.getState().dirty) {
        return (
            <>
                <Button
                    onClick={handlePopOverClick}
                    label="Borrar"
                    className="my-ra-delete-button"
                    key="button"
                    color="error"
                >
                    <ActionDelete />
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopOverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography style={{ padding: 10 }}>Para poder eliminar actividades de la agenda es necesario que guarde la información del formulario antes.</Typography>
                </Popover>
            </>
        )
    } else {
        return (
            <>
                <DeleteButton
                    mutationMode="pessimistic"
                    resource="scheduledActivities"
                    record={record}
                    redirect={location?.pathname}
                    label="borrar agenda"
                >
                    <AccountCircleIcon />
                </DeleteButton>
            </>
        )
    }
};

const ActivityTypeValueInput = props => {
    const { values } = useFormState();
    const form = useForm();
    const [activityTypeValue, setactivityTypeValue] = useState();
    const [responsable, setResponsable] = useState();
    const [primeraCarga, setPrimeraCarga] = useState(true);

    useEffect(() => {
        if (values?.primaryTypeId) {
            getActivityType().then(
                res => {
                    var filtered = res?.elements?.filter(e => e?.id === values?.primaryTypeId);
                    getActivityTypeValue(filtered && filtered?.length > 0 ? filtered[0]?.code : null).then(
                        res => {
                            setactivityTypeValue(res?.elements);
                            if (primeraCarga && props.mode === 'create') {
                                setPrimeraCarga(false)
                                form.change('secondaryTypeId', null);
                            }
                            if(responsable?.role?.name === "LOGISTICS_TECHNICIAN" && !primeraCarga){
                                let secondaryType = res.elements?.find(e => e.value.toLowerCase() === "TEST".toLowerCase())
                                form.change('secondaryTypeId', secondaryType.id);
                            }
                        }
                    )
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.primaryTypeId])

    useEffect(() => {
        if (values?.professional?.id) {
            professionalClient.professionalGet(values?.professional?.id).then(p => {
                if (p?.role?.name === "LOGISTICS_TECHNICIAN" && !primeraCarga){
                    let secondaryType = activityTypeValue?.find(e => e.value.toLowerCase() === "TEST".toLowerCase())
                    form.change('secondaryTypeId', secondaryType?.id);
                }
                setResponsable(p)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.professional?.id])

    if (!values?.primaryTypeId) return null

    return (
        <AutocompleteInput
                label="Subtipología dinámica"
                source="secondaryTypeId"
                choices={responsable?.role?.name === "LOGISTICS_TECHNICIAN" ?activityTypeValue?.filter(t => t.value.toLowerCase() === "TEST".toLowerCase()):activityTypeValue}
                optionText="value"
                optionValue="id"
                options={{ InputProps: { autoComplete: 'off' }, autoComplete: 'off' }}
                resettable={true}
                validate={[required()]}
            />
    );
};

const ContractProfessionalsInput = props => {
    const { professionalTypes } = props;
    const { values } = useFormState();
    var filterType = ['SOCIAL_WORKER', 'PSYCHOLOGIST', 'TASOC', 'THERAPIST', 'LOGISTICS_TECHNICIAN'];

    if (!values?.relatedContract?.id) return null

    var filterId = professionalTypes?.filter(item => filterType.includes(item.value)).map(item => item.id);

    return (
        <ReferenceInput
            label="Responsable"
            source="professional.id"
            reference="professional"
            disabled={props?.disabled}
            filter={{ type: ['SOCIAL_WORKER', 'PSYCHOLOGIST', 'TASOC', 'THERAPIST'], contractId: values?.relatedContract?.id }}
            filterToQuery={(value) => {
                return { nameINumber: value, professionalTypeId: filterId }
            }}
        >
            <AutocompleteInput optionText={(record) => record?.identificationNumber + ' - ' + record?.name + ' ' + record?.surnames} resettable={true} />
        </ReferenceInput>
    );
};

const ContractParticipantsInput = props => {
    const { values } = useFormState();
    const { identity, loading } = useGetIdentity();
    const [disableAdd, setDisableAdd] = useState(true)
    const [disableRemove, setDisableRemove] = useState(true)
    useEffect(() => {
        if (props?.mode && props?.mode === 'edit') {
            scheduledActivitiesProvider?.getManyReference(null, { id: values?.id, pagination: { page: 1, perPage: 100 } }).then(resp => {
                if (!resp?.data?.every(sc => !!sc?.realEndDate)) {
                    setDisableAdd(false)
                }
            })
        } else if (props?.mode && props?.mode === 'create') {
            setDisableAdd(false)
        }
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (props.mode && props.mode === 'edit') {
            scheduledActivitiesProvider?.getManyReference(null, { id: values?.id, pagination: { page: 1, perPage: 500 } }).then(resp => {
                if (!resp?.data?.every(sc => !!sc?.realEndDate)) {
                    setDisableAdd(false)
                }
                if (!(resp?.data && resp?.data?.length === 1 && resp?.data?.every(sc => !!sc?.realEndDate))) {
                    setDisableRemove(false);
                }
            })
        } else if (props?.mode && props?.mode === 'create') {
            setDisableAdd(false)
            setDisableRemove(false);
        }
        // eslint-disable-next-line
    }, [])

    if (loading && !identity) {
        return <div></div>;
    }


    return (
        <ArrayInput label="Participantes" source="participants" validate={[required()]} disabled={identity?.roles === "CONTRACTING_AUTHORITY" || disableAdd}>
            <SimpleFormIterator disableRemove={disableRemove} disableAdd={disableAdd}>
                <FormDataConsumer>
                    {({ scopedFormData, getSource }) => {
                        // Extraemos el índice a partir del source
                        const source = getSource('');
                        const indexMatch = source.match(/\[(\d+)\]/); // Captura el número entre corchetes

                        const index = indexMatch ? indexMatch[1] : null; // Obtenemos el índice o null si no se encuentra

                        return (
                            <div>
                               <AutoCompleteCustom 
                               type={"PARTICIPANT"} 
                               label={"Usuario"} 
                               placeholder={"Buscar participante"} 
                               disabled={disableAdd} 
                               data={scopedFormData} 
                               index={index} 
                               requiredInput={true}
                               filterId={values.relatedContract?.id} 
                               {...props} />

                            </div>
                        );
                    }}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    );
};

const transformScheduledActivityEdit = (data) => {
    const startDate = data?.startDate ? moment(data?.startDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    const endDate = data?.endDate ? moment(data?.endDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    const activity = { id: data?.activity?.id };
    const realStartDate = data?.realStartDate ? moment(data?.realStartDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    const realEndDate = data?.realEndDate ? moment(data?.realEndDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    return {
        ...data,
        startDate,
        endDate,
        activity: activity,
        interactions: null,
        realStartDate: realStartDate,
        realEndDate: realEndDate
    }
}

const transformActivitiesCreate = (data) => {
    // Horas a fecha null setteando horas
    let initHour = data.initHour + ':00';
    let endHour = data.endHour + ':00';

    // Pasamos fechas a formato UTC Z
    const initDate = data?.initDate ? moment(data?.initDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    if (!data?.primaryTypeId) data.secondaryTypeId = null;

    return {
        ...data,
        participants: data?.participants?.map(e => e?.id),
        activityId: data?.id,
        initHour,
        endHour,
        initDate,
        professionalId: data?.professional?.id,
        relatedContractId: data?.relatedContract?.id
    }
}

const transformActivitiesUpdate = (data) => {
    if (!data?.primaryTypeId) data.secondaryTypeId = null;
    return {
        ...data,
        participants: data?.participants?.map(e => e?.id),
        activityId: data?.id,
        professionalId: data?.professional?.id,
        relatedContractId: data?.relatedContract?.id
    }
}

const OptionIconRenderer = choice => {
    return (
        <span>
            <FontAwesomeIcon style={{ marginRight: 10 }} icon={["fas", choice?.record?.value]} />
            {choice?.record?.value}
        </span>
    )
};